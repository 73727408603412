import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

// users
import usersModule from './modules/user_settings/users/user'
import studentModule from './modules/student/students/student'

// study

import offeringModule from './modules/study/offerings/offering'
import termModule from './modules/study/terms/term'
import registrationModule from './modules/study/registrations/registration'

// financial

import paymentModule from './modules/payments/payment'
import transactionModule from './modules/student/transactions/transaction'
import creditModule from './modules/student/credits/credit'

// system
import dashboardModule from './modules/system/dashboard'
import lookupsModule from './modules/system/lookups'
import certificateModule from '@/store/modules/student/certificates/certificate'
import formModule from '@/store/modules/quality/forms/form'
import roleModule from './modules/user_settings/roles/role'
// admission
import admissionModule from './modules/admission/admission'

// students modules
import offeringsRegistrationModule from './modules/student/registrations/offerings_registration'
// Services Settings moudles
//Excuse Module
import excuseModule from '@/store/modules/study/excuses/excuses'
//Calendars Module
import calendarScheduleModule from '@/store/modules/study/calendars/schedule'
import transferModule from './modules/study/transfer/transfer'

//Quality
import questionnairesModule from '@/store/modules/quality/questionnaires/questionnaires'

//Emtyaz
import emtyazModule from '@/store/modules/emtyaz/emtyaz'

//Trinings
import trainingModule from '@/store/modules/student_activities/trainings'

//post graduate
import thesesModule from '@/store/modules/research/theses/theses'

//Appeals
// import appealModule from '@/store/modules/appeals/appeals'

//Grades Recheck
import gradesRecheckModule from '@/store/modules/grades_recheck/grades_recheck'

// services modules
import serviceModules from './modules/student/services/servcie'

// hotels modules
import Hotelmodule from './modules/hotel/hotel'

import TransferOut from './modules/transfer/transferOut'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    packageVersion: process.env.PACKAGE_VERSION || '0'
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion
    }
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
    users: usersModule,
    students: studentModule,
    dashboard: dashboardModule,
    offerings: offeringModule,
    terms: termModule,
    registrations: registrationModule,
    offeringsRegistrations: offeringsRegistrationModule,
    payments: paymentModule,
    lookups: lookupsModule,
    transactions: transactionModule,
    credit: creditModule,
    certificates: certificateModule,
    roles: roleModule,
    emtyaz: emtyazModule,
    excuses: excuseModule,
    calendars: calendarScheduleModule,
    transfer: transferModule,
    questionnaires: questionnairesModule,
    trainings: trainingModule,
    theses: thesesModule,
    // appeals : appealModule,
    gradesRecheck: gradesRecheckModule,
    services: serviceModules,
    Hotel: Hotelmodule,
    admission: admissionModule,
    TransferOut: TransferOut,
  },
})
