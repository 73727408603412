import Resource from '../../api/resource';
import request from '../../utils/request';

class TransferOut extends Resource {
  constructor() {
    super('transferOut');
  }
  hasRequest(){
    return request({
      url: `/transfer/out/hasRequest`,
      method: 'get',
    });
  }

  request(){
    return request({
      url: `/transfer/out/request`,
      method: 'post',
    });
  }
}

export {TransferOut as default};
