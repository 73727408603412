import Resource from '../resource';
import request from '../../utils/request';

class GradesRecheck extends Resource {
    constructor() {
        super('grades-recheck');
    }

    lookups(payload) {
        return request({
            url: `/lookups/get`,
            method: 'post',
            data: payload
        });
    }

    termsLookups() {
        return request({
            url: `/grades-recheck/lookups`,
            method: 'get'
        });
    }

    myPublishedOfferings(payload) {
        return request({
            url: `/grades-recheck/get-course`,
            method: 'post',
            data: payload
        });
    }

    cancelGradesRecheckRequest(gradesRecheck_id, payment_status_id) {
        let url = payment_status_id == 0 ? `/grades-recheck/${gradesRecheck_id}` : `/grades-recheck/cancel-grade-recheck-request/${gradesRecheck_id}`;
        return request({
            url: url,
            method: payment_status_id == 0 ? 'delete' : 'post',

        })
    }

    submitGradesRecheck(query) {
        let url = `/grades-recheck/submit-grade-recheck`;
        return request({
            url: url,
            method: 'POST',
            data: query,
        })
    }

}

export { GradesRecheck as default };
