import request from '@/utils/request';

export function getLookups(query) {
  return request({
    url: '/lookups/get',
    method: 'post',
    data: query,
  });
}

export function getUserLiveData(query={}) {
  return request({
    url: '/users/live-data',
    method: 'get',
    params: query,
  });
}

export function getCurrentTerms(query={}) {
  return request({
    url: '/system/current-terms',
    method: 'get',
    params: query,
  });
}

export function getUniversityData(query={}) {
  return request({
    url: '/university/data',
    method: 'get',
    params: query,
  });
}
