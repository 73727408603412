import Resource from '../../resource';
import request from '../../../utils/request';

class Thesis extends Resource {
    constructor() {
        super('theses');
    }

    get(payload, params = {}) {
        return request({
            url: payload ? `/${this.uri}/${payload}` : `/${this.uri}`,
            method: 'get',
            params,
        })
    }

    put(id, query) {
        return request({
            url: id ? `/${this.uri}/${id}` : `/${this.uri}`,
            method: 'put',
            data: query,
        })
    }
}

export {Thesis as default};
