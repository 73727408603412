export default [
    {
        path: '/students',
        name: 'students',
        component: () => import('@/views/students/student/index'),
        meta: {pageTitle: 'students', breadcrumb: [{text: 'students'}]}
    },
    {
        path: '/students/:id',
        name: 'student-show',
        permission: 'access_users',
        component: () => import('@/views/students/student/show'),
        meta: {
            public: true,
            pageTitle: 'students',
            breadcrumb: [{to: '/students', text: 'students'}, {text: 'student', active: true}],
        },
    },
    {
        path: '/students/edit/:id',
        name: 'student-edit',
        permission: 'access_users',
        component: () => import('@/views/students/student/edit'),
        meta: {
            public: true,
            pageTitle: 'students',
            breadcrumb: [{to: '/students', text: 'students'}, {text: 'student', active: true}],
        },
    },
    {
        path: '/certificates',
        name: 'certificates',
        permission: 'access_certificate',
        component: () => import('@/views/students/certificates/index'),
        meta: {pageTitle: 'Certificates', breadcrumb: [{text: 'Certificates'},],},
    },
    {
        path: '/certificates/show/:id',
        name: 'certificate',
        permission: 'show_certificate',
        component: () => import('@/views/students/certificates/show'),
        meta: {pageTitle: 'certificate', breadcrumb: [{to: '/certificates', text: 'Certificates'}, {text: 'Certificate', active: true}]},
    },
    {
        path: '/certificates/update/:id',
        name: 'certificate-update',
        permission: 'edit_certificate',
        component: () => import('@/views/students/certificates/update'),
        meta: {pageTitle: 'certificate', breadcrumb: [{to: '/certificates', text: 'certificates'}, {text: 'certificate', active: true}]},
    },
    {
        path: '/certificates/external/:id',
        name: 'certificate-external',
        permission: 'show_certificate',
        component: () => import('@/views/students/certificates/external'),
        meta: {pageTitle: 'certificate', breadcrumb: [{to: '/certificates', text: 'certificates'}, {text: 'certificate', active: true}]},
    },
    {
        path: '/payments',
        name: 'payments',
        component: () => import('@/views/students/payments/index'),
        meta: {pageTitle: 'payments', breadcrumb: [{text: 'payments'}]},
    },
    {
        path: '/payments/:id',
        name: 'payment',
        component: () => import('@/views/students/payments/show'),
        meta: {pageTitle: 'payment', breadcrumb: [{to: '/payments', text: 'payments'}, {text: 'payment', active: true}]},
    },
    {
        path: '/transactions',
        name: 'transactions',
        component: () => import('@/views/students/transactions/index'),
        meta: {pageTitle: 'transactions', breadcrumb: [{text: 'transactions'}]},
    },
    {
        path: '/transaction/:id',
        name: 'transaction',
        component: () => import('@/views/students/transactions/show'),
        meta: {pageTitle: 'transaction', breadcrumb: [{to: '/transactions', text: 'transactions'}, {text: 'transaction', active: true}]},
    },
    {
        path: '/credits',
        name: 'credits',
        component: () => import('@/views/students/credits'),
        meta: {pageTitle: 'credits', breadcrumb: [{text: 'credits'}]},
    },
    {
        path: '/credit/:id',
        name: 'credit',
        component: () => import('@/views/students/credits/show'),
        meta: {pageTitle: 'credit', breadcrumb: [{to: '/credits', text: 'credits'}, {text: 'credit', active: true}]},
    }
]
