import Resource from '../resource';
import request from '../../utils/request';

class Training extends Resource {
    constructor() {
        super('trainings');
    }

    lookups() {
        return request({
            url: `/trainings/lookups`,
            method: 'get'
        });
    }

    myTrainings() {
        return request({
            url: `/trainings/my`,
            method: 'post',
        });
    }

    cancelTrainingRequest(training_student_id) {
        let url = `/trainings/cancel-training-request/${training_student_id}`;
        return request({
            url: url,
            method: 'POST',

        })
    }


    applyForTraining(id, query) {
        let url = `/trainings/apply-for-training/${id}`;
        return request({
            url: url,
            method: 'POST',
            data: query,
        })
    }

    put(id, query) {
        let url = '';
        id != null ? url = `/trainings/${id}` : url = `/trainings`;
        return request({
            url: url,
            method: 'POST',
            data: query,
        })
    }

}

export { Training as default };
