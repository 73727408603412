import fileDownload from "js-file-download";
import Transfer from '../../../../api/study/transfer/transfer';

const transfer_Student = new Transfer();

const transferModule = {
  namespaced: true,
  state: {
    lookups:[]
  },
  getters: {
    lookups(state) {
      return state.lookups;
    },
  },
  mutations: {
    SET_LOOKUPS(state, lookups) {
      state.lookups = lookups;
    },
  },
  actions: {
    
  
   
    addTransferRequest({ commit }, payload) {
        return new Promise((resolve, reject) => {
          transfer_Student.SendRequest(payload).then(response => {
            resolve(response);
          }).catch(error => {
            reject(error.response.data.errors[0]);
          });
        });
    },
    listTransferRequests({ commit }) {
      return new Promise((resolve, reject) => {
        transfer_Student.listTransferRequests().then(response => {
          resolve(response);
        }).catch(error => {
          reject(error.response.data.errors[0]);
        });
      });
  },
  getTransferLookup({ commit },query) {
    return new Promise((resolve, reject) => {
      transfer_Student.transferLookups(query).then(response => {
        commit('SET_LOOKUPS', response.data);
        resolve(response);
      }).catch(error => {
        reject(error.response.data.errors[0]);
      });
    });
  },
  deleteTransfer({ commit },transfer_id) {
    return new Promise((resolve, reject) => {
      transfer_Student.deleteTransfer(transfer_id).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error.response.data.errors[0]);
      });
    });
  },
    
    
  },
};

export default transferModule;
